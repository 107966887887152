import { Modal, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { Flag } from '@components/Flag';

import { VoiceArtistFilter } from '../Common/VoiceArtistFilter';
import { CommonFilterSelector } from '@components/SelectorCommonComponents';

export const StyledModal = styled(Modal)`
  z-index: 8100;

  .modal-content {
    border: none;
    border-radius: 1.6rem;
    box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.1);
    padding: 4rem 2.5rem;
    transition: height 0.3s ease-out;
    width: 100%;
  }

  .modal-header {
    border: none;
    display: flex;
    padding: 0;
    position: relative;

    .modal-title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 2.4rem;
      width: 100%;
    }

    .close {
      color: ${({ theme }) => theme.colorV2.textTertiary};
      font-size: 3.2rem;
      font-weight: normal;
      margin: 0;
      margin-top: 0;
      opacity: 1;
      padding: 0;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: -2.4rem;
      z-index: 100;
    }
  }

  .modal-dialog {
    max-width: 94.8rem;
  }

  .modal-body {
    background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
    padding: 0;
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: 2.4rem;
`;

export const StyledLevelFilter = styled(CommonFilterSelector)`
  width: 100% !important;

  .cerf-level-selector__control {
    font-size: 1.8rem !important;
    min-height: 4.4rem;
  }
`;

export const StyledVoiceArtistFilter = styled(VoiceArtistFilter)`
  width: 100% !important;

  .voice-artist-selector__control {
    font-size: 1.8rem !important;
    min-height: 4.4rem;
  }
`;

export const StyledFlag = styled(Flag)`
  background-color: ${({ theme }) => theme.colorV2.utilityInfoBackground};
  border: solid 0.1rem ${({ theme }) => theme.colorV2.filterInputBaseBorder};
  border-radius: 0.8rem;
  font-size: 1.6rem;
  height: 4.4rem;
  padding: 0.4rem 0.8rem;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
`;

export const Textarea = styled.textarea`
  display: block;
  border: solid 0.1rem ${({ theme }) => theme.colorV2.filterInputBaseBorder};
  border-radius: 0.8rem;
  font-size: 1.8rem;
  height: 8.6rem;
  padding: 0.9rem 1.4rem;
  width: 100%;

  &:focus {
    border: solid 0.1rem ${({ theme }) => theme.colorV2.accentPrimary};
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: flex-end;
  padding-top: 1.6rem;
`;
