import { type FormikContextType, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { AVAILABLE_INTERFACE_LANGUAGES_V2, type LanguageV2 } from '@features/content/languages';
import { selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';
import type { OnOpenTranslationPanelParams } from '@features/content/translations';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLexicalItem, selectVocabularyReview } from '@selectors/VocabularyReviewSelectors';

import { LEXICAL_ITEM_TMP_ID } from './constants';
import { LexicalItemFieldWithLocalizationNames, LexicalItemFormikValues } from './types';

export const useVocabularyReview = () => {
  const { languageId, lexicalItemId = LEXICAL_ITEM_TMP_ID } = useParams<{
    languageId: LanguageV2;
    lexicalItemId?: DBId;
  }>();
  const { pathname } = useLocation();
  const formikContext: FormikContextType<LexicalItemFormikValues> = useFormikContext();

  const dispatch = useAppDispatch();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const lexicalItem = useAppSelector(selectLexicalItem);
  const vocabularyReview = useAppSelector(selectVocabularyReview);

  const interfaceLanguages = AVAILABLE_INTERFACE_LANGUAGES_V2;
  const isLexicalItem = pathname.includes('lexical-item');
  const isNewLexicalItem = isLexicalItem && lexicalItemId === LEXICAL_ITEM_TMP_ID;
  const { phrase } = lexicalItem.content;
  const contentTitle = isLexicalItem
    ? isNewLexicalItem
      ? 'Create new lexical item'
      : phrase?.textLocalizations?.find((localization) => localization.language === languageId)?.value
    : `Vocabulary Review ${languageId}`;

  const onSave = () => {
    dispatch(
      VocabularyReviewActionsCreator.saveLexicalItem({
        values: formikContext?.values,
        languageId,
        lexicalItemId: lexicalItem.content.id,
      }),
    );
    formikContext?.resetForm({ values: formikContext?.values });
    dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
  };

  // Update save button availability on content change
  useEffect(() => {
    const isLexicalItemChanged = formikContext?.dirty;

    if (isLexicalItemChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (!isLexicalItemChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formikContext?.dirty]);
  /** Translations widget callbacks */

  const onOpenTranslationPanel = ({
    defaultContextForTranslators,
    fieldName,
    isChangeBlocked,
    isEditDisabled,
    tab,
    type,
    visitedContentId,
  }: OnOpenTranslationPanelParams<LexicalItemFieldWithLocalizationNames>) => {
    dispatch(
      VocabularyReviewActionsCreator.openTranslationsPanel(
        type,
        tab,
        visitedContentId,
        fieldName,
        isChangeBlocked,
        isEditDisabled,
        defaultContextForTranslators,
      ),
    );
  };

  const onRemoveString = (type: ContentTypesType, fieldName: LexicalItemFieldWithLocalizationNames) => {
    dispatch(VocabularyReviewActionsCreator.removeString(type, fieldName));

    const { values, setFieldValue } = formikContext;

    const newValues = values?.[fieldName]?.map((item) => {
      return {
        ...item,
        value: '',
      };
    });

    setFieldValue(fieldName, newValues);
    setFieldValue(`${fieldName}Changed`, true);
    setFieldValue(`${fieldName}Context`, '');
  };

  return {
    contentTitle,
    isLexicalItem,
    isNewLexicalItem,
    interfaceLanguages,
    languageId,
    lexicalItem,
    lexicalItemId,
    vocabularyReview,
    onOpenTranslationPanel,
    onRemoveString,
    onSave,
  };
};
