import { useState } from 'react';
import { Col, Container, Modal } from 'react-bootstrap';
import Tooltip from 'react-tooltip';

import { type CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { DBId } from '@common/types/DBId';
import { WritableInputText } from '@components/WritableInputText';
import { useToast } from '@features/app/toast';
import { constants as contentConstants } from '@features/content';
import type { LanguageV2 } from '@features/content/languages';
import { Button } from '@features/theme';

import { sendAudioRequest } from '../_service';

import {
  ActionsWrapper,
  Label,
  StyledFlag,
  StyledLevelFilter,
  StyledModal,
  StyledRow,
  StyledVoiceArtistFilter,
  Textarea,
} from './styles';

const { CEFR, CEFR_LEVELS } = contentConstants;

type AudioRequestModalProps = {
  language: LanguageV2;
  level: string | null;
  resourceId: DBId;
  phrase: string;
  show: boolean;
  onHide: () => void;
  onSuccess: (requestId: string) => void;
};

export const AudioRequestModal = ({
  language,
  level,
  resourceId,
  phrase,
  show,
  onHide,
  onSuccess,
}: AudioRequestModalProps) => {
  const preselectedLevel = level ? { label: CEFR[level as keyof typeof CEFR], value: level } : null;

  const [inResponseTo, setInResponseTo] = useState('');
  const [notes, setNotes] = useState('');
  const [phraseToUpdate, setPhraseToUpdate] = useState(phrase);
  const [selectedLevel, setSelectedLevel] = useState<CommonFilterOptionType | null>(preselectedLevel);
  const [selectedVoiceArtist, setSelectedVoiceArtist] = useState<CommonFilterOptionType | null>(null);

  const showToast = useToast();

  const levelTypes = CEFR_LEVELS.map((item) => ({ label: CEFR[item as keyof typeof CEFR], value: item }));
  // CEFR Level is optional for Lexical Item audio request
  const isLevelOptional = window.location.pathname.includes('lexical-item');
  let disabled = !selectedLevel || !selectedVoiceArtist || !phraseToUpdate;
  let validationMessage = 'You must select a Level and a Voice Artist and set Phrase to create an audio request!';

  if (isLevelOptional) {
    disabled = !selectedVoiceArtist || !phraseToUpdate;
    validationMessage = 'You must select a Voice Artist and set Phrase to create an audio request!';
  }

  const sendButtonTooltipText = disabled ? validationMessage : '';

  const handleRequestAudio = () => {
    sendAudioRequest({
      language,
      level: selectedLevel?.value,
      resourceId,
      phrase: phraseToUpdate,
      inResponseTo,
      notes,
      voiceArtist: selectedVoiceArtist?.value as string,
    })
      .then((result) => {
        showToast({
          type: 'info',
          title: 'Audio request sent',
        });
        onSuccess(result.data.id);
      })
      .catch(() => {
        showToast({
          type: 'error',
          title: 'Audio request failed',
        });
      })
      .finally(() => {
        onHide();
      });
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Audio Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <StyledRow>
            <Col xs={6}>
              <Label>Resource Id</Label>
              <WritableInputText disabled fontSize="18" value={resourceId} />
            </Col>
            <Col>
              <Label>Level {!isLevelOptional && !selectedLevel && '*'}</Label>
              <StyledLevelFilter
                classNamePrefix="cerf-level-selector"
                options={levelTypes}
                placeholder="Level"
                value={selectedLevel}
                onChange={(value) => setSelectedLevel(value)}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>Language</Label>
              <StyledFlag countries={[language]} />
            </Col>
            <Col>
              <Label>Voice Artist {!selectedVoiceArtist && '*'}</Label>
              <StyledVoiceArtistFilter
                selectedVoiceArtist={selectedVoiceArtist}
                selectedLanguage={{
                  value: language,
                  label: language,
                }}
                setSelectedVoiceArtist={setSelectedVoiceArtist}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>Phrase {!phraseToUpdate && '*'}</Label>
              <WritableInputText
                fontSize="18"
                value={phraseToUpdate}
                onChange={(newPhrase) => setPhraseToUpdate(newPhrase.trim())}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>Notes</Label>
              <Textarea onChange={(evt) => setNotes(evt.target.value)} />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>In response to</Label>
              <Textarea onChange={(evt) => setInResponseTo(evt.target.value)} />
            </Col>
          </StyledRow>
          <ActionsWrapper>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>

            <div data-tip={sendButtonTooltipText} data-for="send-button-tooltip">
              <Button disabled={disabled} variant="primary" onClick={handleRequestAudio}>
                Send
                <Tooltip id="send-button-tooltip" effect="solid" />
              </Button>
            </div>
          </ActionsWrapper>
        </Container>
      </Modal.Body>
    </StyledModal>
  );
};
