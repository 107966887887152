import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import type { ActivityType } from '@features/content/activities';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';

// @TODO We should get rid of the global list of types
export const allTypesMap: { [ContentType in ContentTypesType]: string } = {
  [ContentTypes.course]: 'Course',
  [ContentTypes.level]: 'Level',
  [ContentTypes.chapter]: 'Chapter',
  [ContentTypes.unit]: 'Unit',
  [ContentTypes.activity]: 'Activity',
  [ContentTypes.speakingActivity]: 'Speaking',
  [ContentTypes.exercise]: 'Exercise',
  [ContentTypes.roleplayLesson]: 'AI Roleplay',
  [ContentTypes.lesson]: 'Lesson',
  [ContentTypes.liveLesson]: 'Live lesson',
  [ContentTypes.speakingLesson]: 'Speaking',
  [ContentTypes.checkpoint]: 'Checkpoint',
  [ContentTypes.slidePdf]: 'Slideshow',
  [ContentTypes.slidePptx]: 'Presentation',
  [ContentTypes.review]: 'Review',
  [ContentTypes.string]: 'String',
  [ContentTypes.flashcard]: 'Flashcard',
  [ContentTypes.tip]: 'Tip',
  [ContentTypes.trueFalse]: 'True or False',
  [ContentTypes.multipleChoice]: 'Multiple choice',
  [ContentTypes.phraseBuilder]: 'Phrase builder',
  [ContentTypes.dialogue]: 'Dialogue',
  [ContentTypes.conversation]: 'Conversation',
  [ContentTypes.speechRecognition]: 'Speech Recognition',
  [ContentTypes.typing]: 'Typing',
  [ContentTypes.spelling]: 'Spelling',
  [ContentTypes.highlighter]: 'Highlighter',
  [ContentTypes.matchup]: 'Matchup',
  [ContentTypes.fillgap]: 'Fillgap',
  [ContentTypes.comprehension]: 'Comprehension',
  [ContentTypes.listenRepeat]: 'Listen & Repeat',
  [ContentTypes.certificate]: 'Certificate',
  [ContentTypes.grammarReview]: 'Grammar review',
  [ContentTypes.grammarCategory]: 'Grammar category',
  [ContentTypes.grammarTopic]: 'Grammar topic',
  [ContentTypes.placementTest]: 'Placement test',
  [ContentTypes.entrypoint]: 'Entrypoint',
  [ContentTypes.roleplay]: 'Roleplay',
  [ContentTypes.roleplayCategory]: 'Roleplay Category',
  [ContentTypes.roleplayScenario]: 'Scenario',
  [ContentTypes.lexicalItem]: 'Lexical Item',
  [ContentTypes.vocabularyReview]: 'Vocabulary Review',
};

export const getUrlToNavigate = (
  params: {
    courseId?: string;
    levelId?: string;
    chapterId?: string;
    lessonId?: string;
    activityId?: string;
    categoryId?: string;
    topicId?: string;
    entrypointId?: string;
    languageId?: string;
  },
  id: string,
  type: string,
) => {
  const { courseId, levelId, chapterId, lessonId, activityId, categoryId, topicId, entrypointId, languageId } = params;
  let URLTo: string = '/';

  if (id) {
    if (type === ContentTypes.level && courseId) {
      URLTo = `/course/${courseId}/level/${id}`;
    } else if (type === ContentTypes.grammarCategory && courseId) {
      URLTo = `/course/${courseId}/grammar-review/category/${id}`;
    } else if (type === ContentTypes.entrypoint && courseId) {
      URLTo = `/course/${courseId}/placement-test/entrypoint/${id}`;
    } else if (Object.values(ExerciseTypes).includes(type as any) && courseId && entrypointId) {
      URLTo = `/course/${courseId}/placement-test/entrypoint/${entrypointId}/exercise/${id}`;
    } else if (type === ContentTypes.grammarTopic && courseId && categoryId) {
      URLTo = `/course/${courseId}/grammar-review/category/${categoryId}/topic/${id}`;
    } else if (Object.values(ExerciseTypes).includes(type as any) && courseId && categoryId && topicId) {
      URLTo = `/course/${courseId}/grammar-review/category/${categoryId}/topic/${topicId}/exercise/${id}`;
    } else if (type === ContentTypes.chapter && courseId && levelId) {
      URLTo = `/course/${courseId}/level/${levelId}/chapter/${id}`;
    } else if (type === ContentTypes.lesson && courseId && levelId) {
      if (chapterId) {
        URLTo = `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${id}`;
      } else {
        URLTo = `/course/${courseId}/level/${levelId}/lesson/${id}`;
      }
    } else if (type === ContentTypes.activity && courseId && levelId && lessonId) {
      if (chapterId) {
        URLTo = `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/activity/${id}`;
      } else {
        URLTo = `/course/${courseId}/level/${levelId}/lesson/${lessonId}/activity/${id}`;
      }
    } else if (
      ([ContentTypes.slidePptx, ContentTypes.slidePdf] as string[]).includes(type) &&
      courseId &&
      levelId &&
      lessonId
    ) {
      if (chapterId) {
        URLTo = `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/exercise/${id}`;
      } else {
        URLTo = `/course/${courseId}/level/${levelId}/lesson/${lessonId}/exercise/${id}`;
      }
    } else if (Object.values(ExerciseTypes).includes(type as any) && courseId && levelId && lessonId && activityId) {
      if (chapterId) {
        URLTo = `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/activity/${activityId}/exercise/${id}`;
      } else {
        URLTo = `/course/${courseId}/level/${levelId}/lesson/${lessonId}/activity/${activityId}/exercise/${id}`;
      }
    } else if (type === ContentTypes.roleplayCategory) {
      URLTo = `/roleplay/${languageId}/category/${id}`;
    } else if (type === ContentTypes.roleplayScenario) {
      URLTo = `/roleplay/${languageId}/category/${categoryId}/scenario/${id}`;
    } else {
      URLTo = `/course/${courseId}/content/${id}`;
    }
  }

  return URLTo;
};

// @TODO It should be part of the lessons feature, not global
export const getPositionOfLesson = (id: string, lessons: LessonInterface[]) => {
  const notCountedTypes = ['checkpoint', 'live', 'review', 'certificate'];
  const isLessonCounted = (lesson: LessonInterface) => {
    if (notCountedTypes.includes(lesson.lessonType) || notCountedTypes.includes(lesson.type)) {
      return false;
    }

    return lesson.ready;
  };

  const lessonPositions = lessons?.filter(isLessonCounted).map((lesson: LessonInterface) => lesson.id);
  const checkpointPositions = lessons
    ?.filter(
      (lesson: LessonInterface) => (lesson.lessonType === 'checkpoint' || lesson.type === 'checkpoint') && lesson.ready,
    )
    .map((lesson: LessonInterface) => lesson.id);

  const getLessonPosition = (id: string) => {
    const lessonPosition = lessonPositions.indexOf(id);

    if (lessonPosition >= 0) {
      return lessonPosition;
    }

    const checkpointPosition = checkpointPositions.indexOf(id);

    if (checkpointPosition >= 0) {
      return checkpointPosition;
    }

    return null;
  };

  return getLessonPosition(id);
};

// @TODO It should be part of the activities feature, not global
export const getDescriptionForActivity = (activityType: ActivityType, isLegacyActivity: boolean) => {
  const eachInstructionByActivityType = {
    vocabulary: 'Swipe through the cards to learn new words and phrases',
    memorise: 'Strengthen your new words and phrases',
    dialogue: 'Read and listen to words in context',
    quiz: 'Test yourself on what you’ve learned',
    discover: 'Learn about key language concepts',
    develop: 'Deepen and consolidate your understanding',
    practice: 'Build sentences and put language into context',
    conversation: 'Complete an exercise by writing or speaking and get corrected by native speakers',
    pronunciation: 'Learn great [COURSE LANGUAGE] pronunciation',
    video: 'Watch a video and test your understanding',
    reading: 'Read a text and test your understanding',
    comprehensionSkills: 'Develop your reading and listening in [COURSE LANGUAGE]',
    productiveSkills: 'Develop your speaking and writing in [COURSE LANGUAGE]',
    entity: "Don't worry, it's still visible to learners and you can check it in staging.",
    speakingActivity: "Put into practice all you've learned in this chapter and receive AI feedback.",
  };

  if (isLegacyActivity) {
    return "Don't worry, it's still visible to learners and you can check it in staging.";
  }
  return eachInstructionByActivityType[activityType];
};
