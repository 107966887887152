export const DEFAULT_LEXICAL_ITEMS_LIST_OFFSET = 50;
export const DEFAULT_LEXICAL_ITEMS_LIST_PAGE = 1;

export const LEXICAL_ITEMS_STORAGE_KEY = 'lexical-items';
export const LEXICAL_ITEMS_LAST_UPDATED_STORAGE_KEY = `${LEXICAL_ITEMS_STORAGE_KEY}-last-updated`;

/**
 * Temporary id
 * to use when loading a new Lexical Item view or creating one on the fly from an exercise
 **/
export const LEXICAL_ITEM_TMP_ID = 'new';
