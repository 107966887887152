import { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { UploadTranslationActionsCreator } from '@actionCreators/UploadTranslationActionsCreator';
import { selectUploadTranslationsPanel } from '@selectors/UiSelectors';
import { selectCourse, selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { selectRoleplayCategoryContent } from '@selectors/roleplaySelectors';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { ExerciseInterface } from '@common/interfaces/contentTypes/ExerciseInterface';
import { RoleplayCategoryContentType, RoleplayScenarioContentType } from '@features/content/roleplay';
import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { VocabularyReviewService, type VocabularyReview } from '@features/content/vocabularyReview';
import { selectVocabularyReview } from '@selectors/VocabularyReviewSelectors';

import downloadCompleteIcon from './img/download-complete.svg';
import preloaderIcon from './img/preloader.svg';
import reuploadIcon from './img/reupload.svg';

type UploadTranslationsProps = {
  contentType?: ContentTypesType;
  show: boolean;
  onHide: () => void;
};

type LoadedContentType =
  | ActivityInterface
  | GenericContentInterface
  | ExerciseInterface
  | RoleplayCategoryContentType
  | RoleplayScenarioContentType
  | VocabularyReview
  | null;

export const UploadTranslations = ({ contentType, show, onHide }: UploadTranslationsProps) => {
  const dispatch = useAppDispatch();
  const { phase, payloadText, errors } = useAppSelector(selectUploadTranslationsPanel);

  const loadedCourse = useAppSelector(selectCourse);
  const roleplayCategoryContent = useAppSelector(selectRoleplayCategoryContent);
  const vocabularyReview = useAppSelector(selectVocabularyReview);
  const selectedGroupsOfParentParentContents = useAppSelector(selectSelectedGroupsOfParentParentContents);

  let loadedContent: LoadedContentType = selectedGroupsOfParentParentContents;

  if (contentType === ContentTypes.roleplayCategory) {
    loadedContent = roleplayCategoryContent;
  }

  if (contentType === ContentTypes.lexicalItem) {
    loadedContent = vocabularyReview;
  }

  const [translationsFile, setTranslationsFile] = useState<File | null>(null);

  const close = useCallback(() => {
    onHide();
    setTranslationsFile(null);
    setTimeout(() => {
      dispatch(UploadTranslationActionsCreator.close());
    }, 2000);

    if (phase === 'done' || payloadText) {
      if (loadedContent?.type === ContentTypes.roleplayCategory) {
        dispatch(RoleplayActionCreators.getRoleplayCategory(loadedContent.id));
      } else if (loadedContent?.type === ContentTypes.vocabularyReview) {
        VocabularyReviewService.flushLexicalItemsCache();
      } else if (loadedContent && (loadedContent as GenericContentInterface).categoryId) {
        dispatch(
          CourseEditionActionsCreator.getGroup(
            loadedContent.id,
            (loadedContent as GenericContentInterface).categoryId as string,
          ),
        );
      } else {
        dispatch(CourseEditionActionsCreator.getCourseWithGroups(loadedCourse.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onHide, phase, payloadText, loadedContent, loadedCourse]);

  const onUpload = useCallback(() => {
    if (translationsFile) {
      dispatch(UploadTranslationActionsCreator.upload({ translationsFile }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationsFile]);

  return (
    <UploadTranslationsModal
      phase={phase}
      errorText={payloadText}
      errors={errors}
      translationsFile={translationsFile}
      setTranslationsFile={setTranslationsFile}
      onUpload={onUpload}
      show={show}
      close={close}
    />
  );
};

export type UploadTranslationsPhases = 'start' | 'progress' | 'done';

type UploadTranslationsModalProps = {
  phase: UploadTranslationsPhases;
  errorText?: string;
  errors?: string[];
  translationsFile: File | null;
  setTranslationsFile: (file: File | null) => void;
  onUpload: () => void;
  show: boolean;
  close: () => void;
};

const StartPhaseUploadTranslations = ({
  errorText,
  errors,
  translationsFile,
  setTranslationsFile,
  onUpload,
}: UploadTranslationsModalProps) => {
  return (
    <>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="upload-translations">
          <section className="upload-translations__phase--visible upload-translations__phase0">
            {errorText ? (
              <Fragment>
                <h1 className="upload-translations__title upload-translations__title--error">File upload error</h1>
                <h2 className="upload-translations__explanation">
                  {errors && errors.length
                    ? 'Sorry, the translations below could not be uploaded.'
                    : 'Sorry, there was an error uploading your file, please try again.'}
                </h2>
                {errors && errors.length ? (
                  <div className="upload-translations__explanation-errors">
                    {errors?.map((error: string) => <p className="upload-translations__explanation-error">{error}</p>)}
                  </div>
                ) : (
                  <p className="upload-translations__explanation-error">{errorText}</p>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <h1 className="upload-translations__title">Choose a file</h1>

                <h2 className="upload-translations__explanation">
                  This change will take place in all courses where this content is present and override any existing
                  translations
                </h2>
              </Fragment>
            )}

            <label htmlFor="browse-file-upload" className="file-upload__browse">
              <div
                className={`upload-translations__browse-container ${
                  translationsFile ? 'upload-translations__browse-container--open' : ''
                }`}
              >
                {translationsFile ? (
                  <>
                    <span className="upload-translations__browse-filename" title={translationsFile?.name}>
                      {translationsFile?.name}
                    </span>
                    <img src={reuploadIcon} alt="Reupload this content" className="upload-translations__reupload" />
                  </>
                ) : (
                  <span className="upload-translations__cta">Choose a file</span>
                )}
                <input
                  type="file"
                  className={`upload-translations__browse ${
                    translationsFile ? 'upload-translations__browse--visible' : ''
                  }`}
                  id="browse-file-upload"
                  style={{ display: 'none' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files !== null) {
                      setTranslationsFile(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </label>

            <div className="upload-translations__buttons-container">
              <Button disabled={!translationsFile} onClick={onUpload}>
                Upload
              </Button>
            </div>
          </section>
        </div>
      </Modal.Body>
    </>
  );
};

const ProgressPhaseUploadTranslations = () => (
  <>
    <Modal.Body>
      <div className="upload-translations">
        <section className="upload-translations__phase--visible upload-translations__phase1">
          <img src={preloaderIcon} alt="" className="upload-translations__preloader" />

          <h1 className="upload-translations__title">Upload in progress...</h1>
          <h2 className="upload-translations__explanation">
            This change will take place in all courses where this content is present
          </h2>
        </section>
      </div>
    </Modal.Body>
  </>
);

const DonePhaseUploadTranslations = ({ errorText, close }: UploadTranslationsModalProps) => {
  return (
    <>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="upload-translations">
          <section className="upload-translations__phase--visible upload-translations__phase2">
            {!errorText && <img src={downloadCompleteIcon} alt="" className="upload-translations__download-complete" />}

            <h1 className="upload-translations__title">Translations have been uploaded</h1>

            <Button onClick={close}>Close</Button>
          </section>
        </div>
      </Modal.Body>
    </>
  );
};

export const UploadTranslationsModal = (props: UploadTranslationsModalProps) => {
  return (
    <Modal className="upload-translations-modal" show={props.show} onHide={props.close} centered>
      {props.phase === 'start' && <StartPhaseUploadTranslations {...props} />}
      {props.phase === 'progress' && <ProgressPhaseUploadTranslations />}
      {props.phase === 'done' && <DonePhaseUploadTranslations {...props} />}
    </Modal>
  );
};
