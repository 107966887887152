import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import type { LanguageV2 } from '@features/content/languages';

type SendAudioRequestParams = {
  inResponseTo?: DBId;
  language: LanguageV2;
  level?: string;
  notes?: string;
  phrase: string;
  resourceId: DBId;
  voiceArtist: DBId;
};

export const sendAudioRequest = async ({
  inResponseTo,
  language,
  level,
  notes,
  phrase,
  resourceId,
  voiceArtist,
}: SendAudioRequestParams) => {
  return await apiClient.noErrorsV2.post('/audio-requests', {
    inResponseTo,
    language,
    level,
    notes,
    phrase,
    resourceId,
    voiceArtist,
  });
};
